import React, { useState, useEffect, useRef } from 'react';
import { summarizeFeature } from './services/openai';
import { transcribeAudio as transcribeAudioService } from './services/assemblyAI';
import Button from "./components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "./components/ui/card";
import { ScrollArea } from "./components/ui/scroll-area";
import { Plus, Mic, Rocket, Sparkles, Loader2, StopCircle, Video, Minus, Pencil, Save, Trash2, Share2 } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { useSearchParams, useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { supabase, checkAuth } from './libs/supabaseClient';
import { saveVideo } from './services/videoStorage';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from './services/authService'; // Adjust the import path if necessary

// Simulated function for screen recording
import { extractAudioFromVideo } from './utils/audioExtractor';
import { isMobileDevice } from './utils/deviceDetection';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_KEY = process.env.REACT_APP_ASSEMBLY_AI_API_KEY;
const API_URL = 'https://api.assemblyai.com/v2';

const transcribeAudio = async (audioBlob: Blob): Promise<string> => {
  try {
    // Upload the audio file to AssemblyAI
    const uploadResponse = await fetch(`${API_URL}/upload`, {
      method: 'POST',
      headers: {
        'authorization': API_KEY!,
        'Content-Type': 'audio/webm'
      },
      body: audioBlob
    });

    if (!uploadResponse.ok) {
      throw new Error(`HTTP error! status: ${uploadResponse.status}`);
    }

    const uploadResult = await uploadResponse.json();
    const audioUrl = uploadResult.upload_url;

    // Start the transcription job
    const transcriptResponse = await fetch(`${API_URL}/transcript`, {
      method: 'POST',
      headers: {
        'authorization': API_KEY!,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ audio_url: audioUrl })
    });

    if (!transcriptResponse.ok) {
      throw new Error(`HTTP error! status: ${transcriptResponse.status}`);
    }

    const transcriptResult = await transcriptResponse.json();
    const transcriptId = transcriptResult.id;

    // Poll for the transcription result
    let transcript = '';
    while (true) {
      const pollingResponse = await fetch(`${API_URL}/transcript/${transcriptId}`, {
        headers: { 'authorization': API_KEY! }
      });

      if (!pollingResponse.ok) {
        throw new Error(`HTTP error! status: ${pollingResponse.status}`);
      }

      const result = await pollingResponse.json();

      if (result.status === 'completed') {
        transcript = result.text;
        break;
      } else if (result.status === 'error') {
        throw new Error(`Transcription error: ${result.error}`);
      }

      await new Promise(resolve => setTimeout(resolve, 3000));
    }

    return transcript;
  } catch (error) {
    console.error('Transcription error:', error);
    throw new Error(`An error occurred during transcription: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};

interface ChangelogEntry {
  id: string;
  date: string;
  videoUrl: string;
  featureName: string;
  description: string;
  showRecordButton?: boolean;
}

const fakeEntry: ChangelogEntry = {
  id: '0',
  date: new Date().toISOString().split('T')[0],
  videoUrl: '',
  featureName: 'Welcome to Changelog',
  description: 'Start recording your first feature update!',
  showRecordButton: true
};

interface FeatureChangelogProps {
  subdomain?: string;
  mode?: 'view' | 'edit';
  companySlug?: string;
}

function VideoPlayer({ videoUrl }: { videoUrl: string }) {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className="aspect-video bg-gray-100 rounded-md mb-5 flex items-center justify-center">
      {videoUrl && (
        <video
          src={videoUrl}
          controls
          onLoadedData={handleVideoLoaded}
          style={{ display: isVideoLoaded ? 'block' : 'none' }}
          playsInline
          preload="metadata"
          className="w-full h-full rounded-md"
        />
      )}
      {!isVideoLoaded && videoUrl && (
        <div>Loading video...</div>
      )}
    </div>
  );
}

export default function FeatureChangelog({ mode: propMode, subdomain }: FeatureChangelogProps) {
  const { isAuthenticated, user, loading } = useAuth();
  const navigate = useNavigate();
  const [entries, setEntries] = useState<ChangelogEntry[]>([]);
  const [hasRecordedFirstEntry, setHasRecordedFirstEntry] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const location = useLocation();
  const { companySlug, entryId } = useParams<{ companySlug: string; entryId: string }>();
  const { companySlug: urlCompanySlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  // Add these useEffect hooks for debugging
  useEffect(() => {
    console.log('isAuthenticated:', isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    console.log('hasRecordedFirstEntry:', hasRecordedFirstEntry);
  }, [hasRecordedFirstEntry]);

  console.log('FeatureChangelog rendered', { subdomain, propMode, companySlug, urlCompanySlug });

  useEffect(() => {
    const verifyAuth = async () => {
      const { user, error } = await checkAuth();
      console.log('Auth verification:', user ? 'User authenticated' : 'No user', error ? `Error: ${error.message}` : 'No error');
    };

    verifyAuth();
  }, []);

  useEffect(() => {
    // Extract entryId from mode parameter if it exists
    const modeParam = searchParams.get('mode');
    const viewEntryId = modeParam?.startsWith('view/') ? modeParam.split('/')[1] : null;

    if (viewEntryId) {
      // This is a shared entry view - fetch regardless of auth state
      fetchSingleEntry(viewEntryId);
    } else if (propMode === 'edit') {
      // Only check auth for edit mode
      if (!loading) {
        if (isAuthenticated) {
          fetchEntries();
        } else {
          navigate('/?login=true');
        }
      }
    }
  }, [searchParams, propMode, isAuthenticated, loading]);

  useEffect(() => {
    console.log('Authentication effect:', { isAuthenticated, loading, propMode });
    if (!loading) {
      if (isAuthenticated && propMode === 'edit') {
        fetchEntries();
      } else if (!isAuthenticated && propMode === 'edit') {
        console.log('Redirecting to login');
        navigate('/?login=true');
      }
    }
  }, [isAuthenticated, propMode, navigate, loading]);

  useEffect(() => {
    const accessToken = searchParams.get('access_token');
    if (accessToken) {
      // Set the session using the access token
      supabase.auth.setSession({ access_token: accessToken, refresh_token: '' });
      // Remove the access_token from the URL
      searchParams.delete('access_token');
      window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    }
  }, [searchParams]);

  // Add this near the top of the component, after other useEffects
  useEffect(() => {
    console.log('Authentication state:', { isAuthenticated, propMode });
  }, [isAuthenticated, propMode]);

  const fetchCompanyName = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      console.log('User data:', user);
      if (user && user.user_metadata && user.user_metadata.company_name) {
        console.log('Setting company name from user metadata:', user.user_metadata.company_name);
        setCompanyName(user.user_metadata.company_name);
      } else if (subdomain) {
        console.log('Setting company name from subdomain:', subdomain);
        setCompanyName(subdomain.charAt(0).toUpperCase() + subdomain.slice(1));
      } else if (companySlug) {
        console.log('Setting company name from companySlug:', companySlug);
        setCompanyName(companySlug.charAt(0).toUpperCase() + companySlug.slice(1));
      } else {
        console.log('No company name found');
      }
    } catch (error) {
      console.error('Error fetching company name:', error);
    }
  };

  console.log('FeatureChangelog rendered', { subdomain, propMode, companySlug });

  if (propMode === 'edit' && !companySlug) {
    return <div>Edit mode for landing page</div>;
  }

  // Handle the case for /landing/create
  if (propMode === 'edit' && !companySlug && !subdomain) {
    return <div>Edit mode for landing page</div>;
  }

  const [expandedEntries, setExpandedEntries] = useState<Set<string>>(new Set());
  const [editingEntryId, setEditingEntryId] = useState<string | null>(null);
  const [editingDescription, setEditingDescription] = useState<string>('');
  const [editingCompanyName, setEditingCompanyName] = useState(false);
  const [editingFeatureName, setEditingFeatureName] = useState<string | null>(null);
  const [canCreateEntry, setCanCreateEntry] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [processingEntryId, setProcessingEntryId] = useState<string | null>(null);

  useEffect(() => {
    if (entries.length === 0) {
      setEntries([fakeEntry]);
    }
  }, [])

  useEffect(() => {
    if (entries.length > 0) {
      setExpandedEntries(new Set([entries[0]?.id].filter(Boolean)));
    }
  }, [entries]);

  useEffect(() => {
    console.log("Current entries:", entries);
  }, [entries]);

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  const [recordingState, setRecordingState] = useState<'idle' | 'recording' | 'processing' | 'error'>('idle');
  const [error, setError] = useState<string | null>(null);
  const [isSharing, setIsSharing] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);

  useEffect(() => {
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    setIsLoading(true);
    console.log('Fetching entries. Subdomain:', subdomain);
    try {
      let entries;
      if (subdomain) {
        console.log('Fetching entries for subdomain:', subdomain);
        const { data, error } = await supabase
          .from('changelog_entries')
          .select('*')
          .eq('user_id', subdomain) // Assuming subdomain is stored in user_id
          .order('date', { ascending: false });
        if (error) throw error;
        entries = data;
      } else {
        console.log('Fetching entries for authenticated user');
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const { data, error } = await supabase
            .from('changelog_entries')
            .select('*')
            .eq('user_id', user.id)
            .order('date', { ascending: false });
          if (error) throw error;
          entries = data;
        }
      }

      console.log('Fetched entries:', entries);
      setEntries(entries || []);
      setHasRecordedFirstEntry((entries && entries.length > 0) || false);
    } catch (error) {
      console.error('Error in fetchEntries:', error);
      setEntries([]);
      setHasRecordedFirstEntry(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSingleEntry = async (id: string) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('changelog_entries')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      if (data) {
        setEntries([data]);
        setExpandedEntries(new Set([data.id]));
      }
    } catch (error) {
      console.error('Error fetching entry:', error);
      setEntries([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEntriesByCompany = async (slug: string) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('changelog_entries')
        .select('*')
        .eq('company_slug', slug)
        .order('date', { ascending: false });

      if (error) throw error;
      setEntries(data || []);
    } catch (error) {
      console.error('Error fetching entries by company:', error);
      setEntries([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewEntry = async (newEntry: ChangelogEntry) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const entryWithMetadata = { 
        ...newEntry, 
        user_id: user.id
      };

      const { data, error } = await supabase
        .from('changelog_entries')
        .insert(entryWithMetadata)
        .single();

      if (error) throw error;

      setEntries(prevEntries => [data, ...prevEntries]);
    } catch (error) {
      console.error('Error saving new entry:', error);
      setError(`Failed to save new entry: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  const processRecording = async (videoBlob: Blob) => {
    setRecordingState('processing');
    try {
      const videoUrl = await saveVideo(videoBlob);
      console.log("Video URL:", videoUrl); // Log the video URL
      
      const newEntryId = uuidv4();
      setProcessingEntryId(newEntryId);

      setEntries(prevEntries => [{
        id: newEntryId,
        date: new Date().toISOString().split('T')[0],
        videoUrl: videoUrl, // Ensure this is set correctly
        featureName: 'Processing...',
        description: ''
      }, ...prevEntries]);

      // Start processing in the background
      processInBackground(videoBlob, newEntryId, videoUrl);

    } catch (error) {
      console.error("Error processing recording:", error);
      setRecordingState('error');
      setError(`An error occurred while processing the recording: ${error instanceof Error ? error.message : 'Unknown error'}`);
    }
  };

  const processInBackground = async (videoBlob: Blob, entryId: string, videoUrl: string) => {
    try {
      const audioBlob = await extractAudioFromVideo(videoBlob);
      const transcription = await transcribeAudioService(audioBlob);
      const summary = await summarizeFeature(transcription);
      
      // Update the entry in state
      setEntries(prevEntries => 
        prevEntries.map(entry => 
          entry.id === entryId 
            ? { ...entry, featureName: summary.title, description: summary.summary, videoUrl }
            : entry
        )
      );

      // Update or insert the entry in Supabase
      const { data, error } = await supabase
        .from('changelog_entries')
        .upsert({
          id: entryId,
          featureName: summary.title,
          description: summary.summary,
          date: new Date().toISOString().split('T')[0],
          user_id: (await supabase.auth.getUser()).data.user?.id,
          videoUrl // Ensure this is included
        });

      if (error) throw error;

      setProcessingEntryId(null);
      setRecordingState('idle');
    } catch (error) {
      console.error("Error in background processing:", error);
      setError(`An error occurred during background processing: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setRecordingState('error');
    }
  };

  const startRecording = async () => {
    // First, ensure any existing recording is stopped
    if (isRecording) {
      await stopRecording();
    }

    try {
      let combinedStream;

      if (isMobile) {
        // For mobile devices, we'll use the device's camera and microphone
        const mobileStream = await navigator.mediaDevices.getUserMedia({ 
          video: true, 
          audio: true 
        });
        combinedStream = mobileStream;
      } else {
        // For desktop, use screen capture and microphone
        const displayStream = await navigator.mediaDevices.getDisplayMedia({ 
          video: true,
          audio: true
        });

        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });

        combinedStream = new MediaStream([
          ...displayStream.getVideoTracks(),
          ...audioStream.getAudioTracks()
        ]);
      }

      const mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: 'video/mp4'
      });
      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const videoBlob = new Blob(chunksRef.current, { type: 'video/webm' });
        const url = URL.createObjectURL(videoBlob);
        processRecording(videoBlob);
      };

      mediaRecorder.start();
      setIsRecording(true);
      setRecordingState('recording');
    } catch (error) {
      console.error("Error starting recording:", error);
      setRecordingState('idle');
      setIsRecording(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      
      // Stop all tracks from the current stream
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => {
          track.stop();
        });
      }

      setIsRecording(false);
      setRecordingState('processing');
      mediaRecorderRef.current = null;
    }
  };

  const handleRecordingToggle = () => {
    if (!isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  };

  const toggleEntry = (id: string) => {
    setExpandedEntries(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  console.log("Current entries:", entries);

  const formatDescription = (description: string) => {
    const sections = description.split(/(?=Overview:|Key Benefits:|How It Works:|How to Access:|Use Cases:)/);
  
    return sections.map(section => {
      if (section.startsWith('Overview:')) {
        return `**Overview:**\n${section.replace('Overview:', '').trim()}\n\n`;
      } else if (section.startsWith('Key Benefits:')) {
        const benefits = section.replace('Key Benefits:', '').trim().split('\n');
        const formattedBenefits = benefits.map((benefit, index) => `${index + 1}. ${benefit.trim()}`).join('\n');
        return `**Key Benefits:**\n${formattedBenefits}\n\n`;
      } else if (section.startsWith('How It Works:')) {
        const steps = section.replace('How It Works:', '').trim().split('\n');
        const formattedSteps = steps.map(step => `- ${step.trim()}`).join('\n');
        return `**How It Works:**\n${formattedSteps}\n\n`;
      } else if (section.startsWith('How to Access:')) {
        const access = section.replace('How to Access:', '').trim().split('\n');
        const formattedAccess = access.map((step, index) => `${index + 1}. ${step.trim()}`).join('\n');
        return `**How to Access:**\n${formattedAccess}\n\n`;
      } else if (section.startsWith('Use Cases:')) {
        const cases = section.replace('Use Cases:', '').trim().split('\n');
        const formattedCases = cases.map(useCase => `- ${useCase.trim()}`).join('\n');
        return `**Use Cases:**\n${formattedCases}\n\n`;
      }
      return section;
    }).join('');
  };

  const handleEdit = async (id: string, newDescription: string) => {
    try {
      const { data, error } = await supabase
        .from('changelog_entries')
        .update({ description: newDescription })
        .eq('id', id);

      if (error) throw error;

      setEntries(prevEntries =>
        prevEntries.map(entry =>
          entry.id === id ? { ...entry, description: newDescription } : entry
        )
      );
      setEditingEntryId(null);
      toast.success('Changes saved successfully!');
    } catch (error) {
      console.error('Error updating entry:', error);
      toast.error('Failed to save changes');
    }
  };

  const handleDeleteEntry = async (id: string) => {
    try {
      console.log('Attempting to delete entry with ID:', id);
      
      // Delete from Supabase
      const { error } = await supabase
        .from('changelog_entries')
        .delete()
        .eq('id', id);

      if (error) throw error;

      console.log('Successfully deleted entry from Supabase');

      // Update local state immediately after successful Supabase deletion
      setEntries(prevEntries => {
        const newEntries = prevEntries.filter(entry => entry.id !== id);
        console.log('Updated entries after deletion:', newEntries);
        return newEntries;
      });

    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/');
  };

  // Use location to determine if we're in edit mode
  const isCreateMode = location.pathname.includes('/create');

  console.log("hasRecordedFirstEntry:", hasRecordedFirstEntry);

  const generateShareableUrl = (entryId: string) => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/create?mode=view/${entryId}`;
  };

  const handleShare = (entryId: string) => {
    const url = generateShareableUrl(entryId);
    navigator.clipboard.writeText(url).then(() => {
      toast.success('Link copied to clipboard!', {
        position: "top-right",
        autoClose: 3000,
      });
    }).catch(err => {
      console.error('Failed to copy URL: ', err);
      toast.error('Failed to copy link');
    });
  };

  useEffect(() => {
    // Re-initialize recording capabilities when user logs in
    if (isAuthenticated && propMode === 'edit') {
      setIsRecording(false);
      setCanCreateEntry(true);
      mediaRecorderRef.current = null;
      chunksRef.current = [];
    }
  }, [isAuthenticated, propMode]);

  if (loading) {
    return <div>Loading authentication state...</div>;
  }

  return (
    <div className="min-h-screen bg-white">
      <header className="border-b">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold no-underline">
            <span className="bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">chng</span>
            <span className="text-black">log</span>
          </Link>
          <button 
            onClick={isAuthenticated ? handleLogout : () => navigate('/?login=true')}
            className="px-4 py-2 rounded bg-blue-500 text-white bg-purple-600 text-white hover:bg-purple-700"
          >
            {isAuthenticated ? 'Sign Out' : 'Login'}
          </button>
        </div>
      </header>

      {isAuthenticated && propMode === 'edit' && (
        <div className="container mx-auto p-4">
          <button
            onClick={handleRecordingToggle}
            className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700"
          >
            {isRecording ? 'Stop Recording' : 'Start Recording'}
          </button>
        </div>
      )}

      <div className="container mx-auto p-4 min-h-screen">
        <h1 className="text-4xl font-bold text-center mb-8 text-gray-900">
          {companyName ? `${companyName}'s Changelog` : 'Changelog'}
        </h1>
        
        {recordingState === 'processing' && (
          <div className="mb-4 flex items-center justify-center text-blue-600">
            <Loader2 className="animate-spin mr-2 h-4 w-4" />
            <span>Creating update...</span>
          </div>
        )}
        {recordingState === 'error' && (
          <div className="mb-4 text-red-500 text-center">
            An error occurred. Please try again.
          </div>
        )}

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <Loader2 className="animate-spin h-8 w-8 text-blue-500" />
          </div>
        ) : entries && entries.length > 0 ? (
          entries.map((entry, index) => (
            <React.Fragment key={entry?.id ?? index}>
              {index > 0 && <hr className="my-6 border-t border-gray-200" />}
              <Card className="mb-6 overflow-hidden bg-white shadow-md rounded-lg max-w-4xl mx-auto">
                <CardHeader className="bg-gradient-to-r from-purple-500 to-indigo-600 p-6">
                  <div className="flex items-center justify-between text-white">
                    <div className="flex flex-col items-start">
                      <p className="text-indigo-100 text-lg">
                        {new Date(entry?.date).toISOString().split('T')[0]}
                      </p>
                      <h2 className="text-3xl font-bold">
                        {isAuthenticated && editingFeatureName === entry?.id ? (
                          <input
                            type="text"
                            value={entry?.featureName.replace(/^Name:\s*/, '')}
                            onChange={(e) => {
                              setEntries(prevEntries =>
                                prevEntries.map(prevEntry =>
                                  prevEntry.id === entry?.id ? { ...prevEntry, featureName: e.target.value } : prevEntry
                                )
                              );
                            }}
                            onBlur={() => setEditingFeatureName(null)}
                            autoFocus
                            className="bg-transparent w-full"
                          />
                        ) : (
                          <span onClick={() => isAuthenticated && setEditingFeatureName(entry?.id)} className={isAuthenticated ? "cursor-pointer" : ""}>
                            {entry?.featureName.replace(/^Name:\s*/, '')}
                          </span>
                        )}
                      </h2>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={() => handleShare(entry?.id)}
                        className="text-white hover:bg-white hover:bg-opacity-20 rounded-full p-1 mr-2"
                      >
                        <Share2 className="h-5 w-5" />
                      </button>
                      {isAuthenticated && entry?.id !== '0' && (
                        <button
                          onClick={() => handleDeleteEntry(entry?.id)}
                          className="text-white hover:bg-white hover:bg-opacity-20 rounded-full p-1 mr-2"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      )}
                      <button
                        onClick={() => toggleEntry(entry?.id)}
                        className="text-white hover:bg-white hover:bg-opacity-20 rounded-full p-1"
                      >
                        {expandedEntries.has(entry?.id) ? (
                          <Minus className="h-6 w-6" />
                        ) : (
                          <Plus className="h-6 w-6" />
                        )}
                      </button>
                    </div>
                  </div>
                </CardHeader>
                {expandedEntries.has(entry?.id) && (
                  <CardContent className="p-6">
                    {entry?.videoUrl && (
                      <VideoPlayer videoUrl={entry.videoUrl} />
                    )}
                    {processingEntryId === entry?.id && (
                      <div className="mb-5 flex items-center justify-center">
                        <Loader2 className="animate-spin mr-2 h-4 w-4" />
                        <span>Generating summary...</span>
                      </div>
                    )}
                    <div className="relative">
                      {isAuthenticated && editingEntryId === entry?.id ? (
                        <>
                          <ReactQuill
                            theme="snow"
                            value={editingDescription}
                            onChange={setEditingDescription}
                            modules={{
                              toolbar: [
                                [{ 'header': [1, 2, 3, false] }],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{'list': 'ordered'}, {'list': 'bullet'}],
                                ['link', 'image'],
                                ['clean']
                              ],
                            }}
                          />
                          <Button 
                            onClick={() => handleEdit(entry?.id, editingDescription)} 
                            className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                          >
                            <Save className="w-4 h-4 mr-2" />
                            Save
                          </Button>
                        </>
                      ) : (
                        <div 
                          className="prose prose-lg max-w-none cursor-pointer" 
                          onClick={() => {
                            isAuthenticated && setEditingEntryId(entry?.id);
                            setEditingDescription(entry?.description);
                          }}
                        >
                          {entry?.id === '0' ? (
                            <p className="text-gray-600 italic">
                              This is where your feature description will go. Explain what's new, how it works, and why it's valuable to your users.
                            </p>
                          ) : (
                            <ReactMarkdown>{formatDescription(entry?.description || '')}</ReactMarkdown>
                          )}
                        </div>
                      )}
                    </div>
                  </CardContent>
                )}
              </Card>
            </React.Fragment>
          ))
        ) : (
          <div className="text-center text-gray-500">
            No changelog entries found. {isAuthenticated && 'Start by recording your first feature update!'}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}