const API_URL = 'https://api.openai.com/v1/chat/completions';

export const getChatGPTResponse = async (prompt: string): Promise<string> => {
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
    },
    body: JSON.stringify({
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: prompt }],
      temperature: 0.7
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data.choices[0].message.content;
};

import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true // Only use this for development
});

export const summarizeFeature = async (transcript: string): Promise<{ title: string, summary: string }> => {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: "You are a tool that summarizes video transcripts into structured feature announcements. Extract the feature name and provide a summary. The feature name should be a short, concise title. The summary should include: Overview (brief paragraph), Key Benefits (bullet points), How It Works (numbered list), How to Access (brief paragraph), and Use Cases (bullet points). Limit each section to 3-5 points. Add a blank line between each section for readability. Do not use emojis."
        },
        {
          role: "user",
          content: `Here is the transcript: ${transcript}. Please provide the feature name and summarize this into a feature announcement.`
        }
      ],
      temperature: 0.7,
      max_tokens: 500
    });

    const content = response.choices[0].message.content || "No summary generated.";
    const [title, ...summaryParts] = content.split('\n\n');

    return {
      title: title.replace(/^Feature:?\s*/i, '').trim(),
      summary: summaryParts.join('\n\n')
    };
  } catch (error) {
    console.error('Error summarizing feature:', error);
    return {
      title: "Error",
      summary: "An error occurred while summarizing the feature."
    };
  }
}

export async function summarizeTranscription(transcription: string): Promise<string> {
  // Implement the summarization logic here
  // Make sure to return a string
  return "Summary of the transcription";
}

export async function performTranscription(audioBlob: Blob): Promise<string> {
  const formData = new FormData();
  formData.append('file', audioBlob, 'audio.webm');
  formData.append('model', 'whisper-1');

  const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const result = await response.json();
  return result.text;
}