import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './libs/supabaseClient';
import Button from "./components/ui/button"
import { Card, CardContent } from "./components/ui/card"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./components/ui/accordion"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./components/ui/dialog"
import { Input } from "./components/ui/input"
import { Label } from "./components/ui/label"
import { Checkbox } from "./components/ui/checkbox"
import { signUp, signIn, signOut, useAuth, signInWithGoogle } from './services/authService';
import { Eye, EyeOff } from 'lucide-react';

export default function LandingPage() {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showSignupPassword, setShowSignupPassword] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [authError, setAuthError] = useState('');

  const handleTryForFree = async () => {
    if (isAuthenticated) {
      navigate('/create?mode=edit');
    } else {
      setIsSignupOpen(true);
    }
  }

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await signIn(email, password);
      if ('error' in result) throw result.error;
      console.log('Logged in:', result.user);
      setIsLoginOpen(false);
      navigate('/create?mode=edit');
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login error (e.g., show error message)
    }
  };

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: { 
            company_name: companyName,
            subdomain: companyName.toLowerCase().replace(/\s+/g, '-')
          }
        }
      });

      if (error) throw error;

      console.log('Signup data:', data);

      if (data.user) {
        console.log('User created:', data.user);
        
        // Close the signup modal
        setIsSignupOpen(false);
        
        // Open the login modal
        setIsLoginOpen(true);
        
        // Optionally, you can pre-fill the email in the login form
        setEmail(data.user.email ?? '');
        
        // Show a success message
        alert('Account created successfully! Please log in to continue.');
      } else {
        console.log('User creation failed or email confirmation required.');
        // Handle this case - perhaps show a message to the user about email confirmation
      }
    } catch (error) {
      console.error('Signup failed:', error);
      // Handle signup error (e.g., show error message to user)
    }
  };

  const handleGoogleSignIn = async () => {
    setIsGoogleLoading(true);
    setAuthError('');
    try {
      const result = await signInWithGoogle();
      if ('error' in result) throw result.error;
      
      setIsLoginOpen(false);
      setIsSignupOpen(false);
      navigate('/create?mode=edit');
    } catch (error: any) {
      console.error('Google sign in failed:', error);
      setAuthError(error.message || 'Google sign in failed. Please try again.');
    } finally {
      setIsGoogleLoading(false);
    }
  };

  // Clear error when modals are closed or switched
  useEffect(() => {
    setAuthError('');
  }, [isLoginOpen, isSignupOpen]);

  const GoogleButton = () => (
    <div className="mt-4">
      {authError && (
        <div className="mb-4 p-2 text-sm text-red-600 bg-red-50 rounded-md">
          {authError}
        </div>
      )}
      <Button
        type="button"
        variant="outline"
        className="w-full flex items-center justify-center gap-2"
        onClick={handleGoogleSignIn}
        disabled={isGoogleLoading}
      >
        {isGoogleLoading ? (
          <span className="loading loading-spinner loading-sm" />
        ) : (
          <>
            <img 
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
              alt="Google" 
              className="w-5 h-5" 
            />
            Sign in with Google
          </>
        )}
      </Button>
      <div className="relative my-4">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="bg-white px-2 text-gray-500">Or continue with</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-white">
      <header className="border-b">
        <div className="container mx-auto px-4 py-4">
          <nav className="flex justify-between items-center">
            <div className="flex items-center space-x-8">
              <div className="text-2xl font-bold">
                <span className="bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">chng</span>
                <span>log</span>
              </div>
              <div className="hidden md:flex space-x-4">
                <a href="#why-choose" className="text-gray-600 hover:text-gray-900">Features</a>
                <a href="#how-it-works" className="text-gray-600 hover:text-gray-900">How it works</a>
                <a href="#faq" className="text-gray-600 hover:text-gray-900">FAQ</a>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Dialog open={isLoginOpen} onOpenChange={setIsLoginOpen}>
                <DialogTrigger asChild>
                  <Button variant="outline">Log in</Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px] bg-white">
                  <DialogHeader>
                    <DialogTitle className="text-center">
                      <span className="bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">chng</span>
                      <span>log</span>
                    </DialogTitle>
                    <h2 className="text-2xl font-bold text-center text-gray-900">Sign in to your account</h2>
                  </DialogHeader>
                  <GoogleButton />
                  <form onSubmit={handleLogin} className="space-y-4 mt-4">
                    <div>
                      <Label htmlFor="email">Email address</Label>
                      <Input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="mt-1" />
                    </div>
                    <div>
                      <Label htmlFor="password">Password</Label>
                      <div className="relative">
                        <Input 
                          id="password" 
                          type={showPassword ? "text" : "password"} 
                          value={password} 
                          onChange={(e) => setPassword(e.target.value)} 
                          required 
                          className="mt-1 pr-10" 
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 pr-3 flex items-center"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <EyeOff className="h-5 w-5 text-gray-500" /> : <Eye className="h-5 w-5 text-gray-500" />}
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <Checkbox id="remember" />
                        <Label htmlFor="remember">Remember me</Label>
                      </div>
                      <div className="text-sm">
                        <a href="#" className="font-medium text-purple-600 hover:text-purple-500">
                          Forgot your password?
                        </a>
                      </div>
                    </div>
                    <Button type="submit" className="w-full bg-purple-600 text-white hover:bg-purple-700">Sign in</Button>
                  </form>
                  <p className="mt-4 text-center text-sm text-gray-600">
                    Don't have an account?{' '}
                    <Button variant="default" className="p-0 h-auto font-medium text-purple-600 hover:text-purple-500" onClick={() => { setIsLoginOpen(false); setIsSignupOpen(true); }}>
                      Sign up
                    </Button>
                  </p>
                </DialogContent>
              </Dialog>
              <Dialog open={isSignupOpen} onOpenChange={setIsSignupOpen}>
                <DialogTrigger asChild>
                  <Button className="bg-purple-600 text-white hover:bg-purple-700">Sign Up</Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px] bg-white">
                  <DialogHeader>
                    <DialogTitle className="text-center">
                      <span className="bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">chng</span>
                      <span>log</span>
                    </DialogTitle>
                    <h2 className="text-2xl font-bold text-center text-gray-900">Create your account</h2>
                  </DialogHeader>
                  <GoogleButton />
                  <form onSubmit={handleSignup} className="space-y-4 mt-4">
                    <div>
                      <Label htmlFor="signup-email">Email address</Label>
                      <Input 
                        id="signup-email" 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                        className="mt-1" 
                      />
                    </div>
                    <div>
                      <Label htmlFor="signup-password">Password</Label>
                      <div className="relative">
                        <Input 
                          id="signup-password" 
                          type={showSignupPassword ? "text" : "password"} 
                          value={password} 
                          onChange={(e) => setPassword(e.target.value)} 
                          required 
                          className="mt-1 pr-10" 
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 pr-3 flex items-center"
                          onClick={() => setShowSignupPassword(!showSignupPassword)}
                        >
                          {showSignupPassword ? <EyeOff className="h-5 w-5 text-gray-500" /> : <Eye className="h-5 w-5 text-gray-500" />}
                        </button>
                      </div>
                    </div>
                    <div>
                      <Label htmlFor="signup-company">Company name</Label>
                      <Input 
                        id="signup-company" 
                        type="text" 
                        value={companyName} 
                        onChange={(e) => setCompanyName(e.target.value)} 
                        required 
                        className="mt-1" 
                      />
                    </div>
                    <Button type="submit" className="w-full bg-purple-600 text-white hover:bg-purple-700">Sign up</Button>
                  </form>
                  <p className="mt-4 text-center text-sm text-gray-600">
                    Already have an account?{' '}
                    <Button variant="default" className="p-0 h-auto font-medium text-purple-600 hover:text-purple-500" onClick={() => { setIsSignupOpen(false); setIsLoginOpen(true); }}>
                      Sign in
                    </Button>
                  </p>
                </DialogContent>
              </Dialog>
            </div>
          </nav>
        </div>
      </header>

      <main>
        <section className="py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">
                Create Effortless Feature Updates
              </h1>
              <p className="text-xl mb-8 text-gray-600">
                Press record, explain your feature, and we'll handle the rest. Keep users informed and excited about your product.
              </p>
              <div className="flex justify-center mb-12">
                <Button onClick={handleTryForFree} className="bg-purple-600 text-white hover:bg-purple-700 px-6 py-3 text-lg">Try For Free</Button>
              </div>
            </div>
          </div>
        </section>

        <section id="why-choose" className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-12 text-center">Why choose chnglog? 🤔</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <Card>
                <CardContent className="p-6">
                  <h3 className="text-xl font-semibold mb-2">Boost Engagement 🚀</h3>
                  <p className="text-gray-600">Keep users informed about new features and improvements, increasing product adoption and satisfaction.</p>
                </CardContent>
              </Card>
              <Card>
                <CardContent className="p-6">
                  <h3 className="text-xl font-semibold mb-2">Reduce Support Load 🎧</h3>
                  <p className="text-gray-600">Proactively communicate changes to reduce support tickets and questions about product updates.</p>
                </CardContent>
              </Card>
              <Card>
                <CardContent className="p-6">
                  <h3 className="text-xl font-semibold mb-2">Build Trust 🤝</h3>
                  <p className="text-gray-600">Demonstrate transparency and commitment to continuous improvement, fostering user trust and loyalty.</p>
                </CardContent>
              </Card>
              <Card>
                <CardContent className="p-6">
                  <h3 className="text-xl font-semibold mb-2">Simplify Communication 💬</h3>
                  <p className="text-gray-600">Streamline the process of sharing updates with your team and customers, ensuring everyone stays in the loop.</p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        <section id="how-it-works" className="py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-12 text-center">How chnglog works 🛠️</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="flex flex-col items-center text-center">
                <div className="bg-purple-100 rounded-full p-4 mb-4">
                  <span className="text-3xl">🎙️</span>
                </div>
                <h3 className="text-xl font-semibold mb-2">1. Record your update</h3>
                <p className="text-gray-600">Simply press record and explain your new feature.</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="bg-blue-100 rounded-full p-4 mb-4">
                  <span className="text-3xl">🤖</span>
                </div>
                <h3 className="text-xl font-semibold mb-2">2. AI generates changelog</h3>
                <p className="text-gray-600">Our AI transforms your recording into a well-structured changelog entry.</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="bg-green-100 rounded-full p-4 mb-4">
                  <span className="text-3xl">📢</span>
                </div>
                <h3 className="text-xl font-semibold mb-2">3. Share and track</h3>
                <p className="text-gray-600">Publish your changelog and monitor user engagement with your updates.</p>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">Frequently Asked Questions 🤓</h2>
            <Accordion type="single" collapsible className="max-w-2xl mx-auto">
              <AccordionItem value="item-1">
                <AccordionTrigger>How does chnglog work?</AccordionTrigger>
                <AccordionContent>
                  chnglog uses advanced AI to convert your spoken feature descriptions into well-structured changelog entries. Simply record yourself talking about the feature, and our system will handle the rest.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2">
                <AccordionTrigger>Can I edit the generated changelog entries?</AccordionTrigger>
                <AccordionContent>
                  Yes, you have full control over the final content. Our AI generates a draft, which you can then edit, refine, or approve as is before publishing.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-3">
                <AccordionTrigger>How does chnglog help with user engagement?</AccordionTrigger>
                <AccordionContent>
                  By simplifying the process of creating and sharing updates, chnglog helps you communicate more frequently and effectively with your users. This increased communication can lead to higher feature adoption rates and overall user satisfaction.
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-4">Become a changelog pro 🏆</h2>
            <p className="text-xl mb-8">
              Unlimited feature updates for just $12/month.
            </p>
            <Button 
              onClick={handleTryForFree} 
              className="bg-gradient-to-r from-purple-600 to-blue-500 text-white hover:from-purple-700 hover:to-blue-600 px-6 py-3 text-lg"
            >
              Start for Free
            </Button>
          </div>
        </section>
      </main>

      <footer className="bg-gray-100 py-8">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold">
              <span className="bg-gradient-to-r from-purple-600 to-blue-500 text-transparent bg-clip-text">chng</span>
              <span className="text-gray-700">log</span>
            </div>
            <div className="text-gray-600">
              © 2024 chnglog. All rights reserved.
            </div>
          </div>
        </div>
      </footer>

      {/* Existing Login Modal */}
      <Dialog open={isLoginOpen} onOpenChange={setIsLoginOpen}>
        {/* ... existing login modal content ... */}
      </Dialog>

      {/* Existing Signup Modal */}
      <Dialog open={isSignupOpen} onOpenChange={setIsSignupOpen}>
        {/* ... existing signup modal content ... */}
      </Dialog>
    </div>
  );
}