import { supabase } from '../libs/supabaseClient'; // Adjust this import path as needed

import { v4 as uuidv4 } from 'uuid';

const refreshSession = async () => {
  console.log('Attempting to refresh session');
  const { data, error } = await supabase.auth.getSession();
  if (error) {
    console.error('Failed to get session:', error);
    throw new Error('Authentication failed');
  }
  if (!data.session) {
    console.error('No active session');
    throw new Error('No active session');
  }
  console.log('Session refreshed successfully');
  return data.session;
};

export const saveVideo = async (videoBlob: Blob): Promise<string> => {
  try {
    // Refresh the session before attempting to upload
    const session = await refreshSession();
    if (!session) {
      throw new Error('No active session');
    }

    const videoId = uuidv4();
    const filePath = `videos/${videoId}.webm`;

    const { data, error } = await supabase.storage
      .from('changelog-videos')  // Verify this matches your Supabase bucket name
      .upload(filePath, videoBlob);

    if (error) {
      throw error;
    }

    // Get public URL for the uploaded video
    const { data: publicUrlData } = supabase.storage
      .from('changelog-videos')
      .getPublicUrl(filePath);

    return publicUrlData.publicUrl;
  } catch (error) {
    console.error('Error saving video:', error);
    throw error;
  }
};

// You can keep these functions for local fallback if needed
export const saveVideoLocally = async (videoBlob: Blob): Promise<string> => {
    const db = await openDatabase();
    const videoId = uuidv4();
    await saveToIndexedDB(db, videoId, videoBlob);
    return videoId;
};

const openDatabase = (): Promise<IDBDatabase> => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('VideoStorage', 1);
        request.onupgradeneeded = () => request.result.createObjectStore('videos');
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
};

const saveToIndexedDB = (db: IDBDatabase, videoId: string, videoBlob: Blob): Promise<void> => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(['videos'], 'readwrite');
        const store = transaction.objectStore('videos');
        const request = store.put(videoBlob, videoId);
        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
    });
};