import { supabase } from '../libs/supabaseClient'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Session } from '@supabase/supabase-js';
import Cookies from 'js-cookie';

// Rename the local interface to avoid conflict
interface AppUser {
  // Add properties that your user object should have
  id?: string;
  name?: string;
  email?: string;
  // Add any other properties your user object might have
}

// Add this function to set the auth cookie
export function setAuthCookie(session: Session) {
  Cookies.set('supabase-auth', JSON.stringify(session), {
    domain: '.chnglog.co', // Ensure this is set to your root domain
    expires: new Date((session.expires_at ?? Date.now() / 1000) * 1000),
    sameSite: 'lax',
    secure: process.env.NODE_ENV === 'production',
  });
};

export const signUp = async (email: string, password: string, companyName: string) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: { company_name: companyName }
    }
  })
  if (error) throw error
  if (data.session) setAuthCookie(data.session)
  return data
}

export const signIn = async (email: string, password: string) => {
  const { data, error } = await supabase.auth.signInWithPassword({ email, password })
  if (error) throw error
  if (data.session) setAuthCookie(data.session)
  return data
}

export const signOut = async () => {
  const { error } = await supabase.auth.signOut()
  if (error) throw error
  Cookies.remove('supabase-auth', { domain: '.chnglog.co' })
}

export const getSession = async () => {
  const { data: { session }, error } = await supabase.auth.getSession()
  if (error) throw error
  if (session) setAuthCookie(session)
  return session
}

export const getCurrentUser = () => {
  // Implementation of getCurrentUser
};

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<AppUser | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      const session = await getSession();
      setIsAuthenticated(!!session);
      setUser(session?.user ?? null);
      setLoading(false);
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setIsAuthenticated(!!session);
      setUser(session?.user ?? null);
      setLoading(false);
      if (session) setAuthCookie(session)
      console.log('Auth state updated:', { event, isAuthenticated: !!session, user: session?.user });
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return { isAuthenticated, user, loading };
}

// Keep this listener for debugging purposes
supabase.auth.onAuthStateChange((event, session) => {
  console.log('Auth state change event:', event);
  console.log('New session:', session);
  if (session) setAuthCookie(session)
});

export const signInWithGoogle = async () => {
  try {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}/create?mode=edit`
      }
    });
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error signing in with Google:', error);
    throw error;
  }
};