import React, { forwardRef, ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  variant?: 'default' | 'outline' | 'destructive';
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'default', children, ...props }, ref) => {
    const baseStyles = "px-4 py-2 rounded";
    const variantStyles = variant === 'outline' 
      ? "border border-current bg-transparent" 
      : "bg-blue-500 text-white";

    return (
      <button 
        ref={ref} 
        className={`${baseStyles} ${variantStyles} ${className}`} 
        {...props}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;