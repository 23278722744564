import { createClient, Session } from '@supabase/supabase-js'
import Cookies from 'js-cookie';

console.log('Environment variables:', process.env);
console.log('Supabase URL:', process.env.REACT_APP_SUPABASE_URL ? '[REDACTED]' : 'undefined');
console.log('Supabase Key:', process.env.REACT_APP_SUPABASE_ANON_KEY ? '[REDACTED]' : 'undefined');

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase URL or Anon Key');
}

// Create the Supabase client
export const supabase = createClient(
  supabaseUrl,
  supabaseKey,
  {
    auth: {
      storage: window.localStorage, // Use localStorage for session persistence
      persistSession: true,
      autoRefreshToken: true,
    },
  }
);

console.log('Supabase client created:', supabase ? 'Success' : 'Failed');

// Utility to manually set a cookie for cross-subdomain sharing
export const setAuthCookie = (session: Session) => {
  const domain = process.env.NEXT_PUBLIC_SITE_URL
    ? new URL(process.env.NEXT_PUBLIC_SITE_URL).hostname
    : undefined;

  Cookies.set('supabase-auth', JSON.stringify(session), {
    domain: domain?.startsWith('www.') ? domain.slice(4) : domain,
    path: '/',
    expires: new Date((session.expires_at ?? Math.floor(Date.now() / 1000) + 3600) * 1000),
    sameSite: 'lax',
    secure: process.env.NODE_ENV === 'production',
  });
};

// Function to check authentication status
export const checkAuth = async () => {
  const { data: { user }, error } = await supabase.auth.getUser();
  console.log('Auth check:', user ? 'User authenticated' : 'No user', error ? `Error: ${error.message}` : 'No error');
  return { user, error };
};

// Handle authentication state changes
supabase.auth.onAuthStateChange((event, session) => {
  console.log('Auth state change event:', event, 'New session:', session);
  if (session) {
    setAuthCookie({
      ...session,
      expires_at: session.expires_at ?? 0 // Provide a default value if undefined
    });
  }
});
