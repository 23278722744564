const API_KEY = process.env.REACT_APP_ASSEMBLY_AI_API_KEY;
const API_URL = 'https://api.assemblyai.com/v2';

export const transcribeAudio = async (audioBlob: Blob): Promise<string> => {
  try {
    // Upload the audio file to AssemblyAI
    const uploadResponse = await fetch(`${API_URL}/upload`, {
      method: 'POST',
      headers: {
        'authorization': API_KEY!,
        'Content-Type': 'audio/webm'
      },
      body: audioBlob
    });

    if (!uploadResponse.ok) {
      throw new Error(`HTTP error! status: ${uploadResponse.status}`);
    }

    const uploadResult = await uploadResponse.json();
    const audioUrl = uploadResult.upload_url;

    // Start the transcription job
    const transcriptResponse = await fetch(`${API_URL}/transcript`, {
      method: 'POST',
      headers: {
        'authorization': API_KEY!,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ audio_url: audioUrl })
    });

    if (!transcriptResponse.ok) {
      throw new Error(`HTTP error! status: ${transcriptResponse.status}`);
    }

    const transcriptResult = await transcriptResponse.json();
    const transcriptId = transcriptResult.id;

    // Poll for the transcription result
    let transcript = '';
    while (true) {
      const pollingResponse = await fetch(`${API_URL}/transcript/${transcriptId}`, {
        headers: { 'authorization': API_KEY! }
      });

      if (!pollingResponse.ok) {
        throw new Error(`HTTP error! status: ${pollingResponse.status}`);
      }

      const result = await pollingResponse.json();

      if (result.status === 'completed') {
        transcript = result.text;
        break;
      } else if (result.status === 'error') {
        throw new Error(`Transcription error: ${result.error}`);
      }

      await new Promise(resolve => setTimeout(resolve, 3000));
    }

    return transcript;
  } catch (error) {
    console.error('Transcription error:', error);
    throw new Error(`An error occurred during transcription: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};