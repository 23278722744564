import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

export const extractAudioFromVideo = async (videoBlob: Blob): Promise<Blob> => {
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();

    const videoData = await fetchFile(videoBlob);
    await ffmpeg.writeFile('input.webm', videoData);

    await ffmpeg.exec(['-i', 'input.webm', '-vn', '-acodec', 'libmp3lame', 'output.mp3']);

    const audioData = await ffmpeg.readFile('output.mp3');
    const audioBuffer = audioData instanceof Uint8Array ? audioData : new Uint8Array(Buffer.from(audioData as string));
    return new Blob([audioBuffer], { type: 'audio/mp3' });
};